<template>
  <div>
    <UiHero title="Say BTTR. Ontdek wat jouw data te zeggen heeft.">
      <template #description>
        Geen eindeloze zoektochten in dashboards, maar direct toegang tot de inzichten die ertoe doen.
        Say BTTR maakt het mogelijk om met je data te praten. Een goed gesprek, maar dan met je data.
        Speel direct in op de belangrijkste inzichten uit jouw data en zet die in voor de groei van je organisatie.
      </template>
      <UiButton
        :to="{name: 'tool'}"
        icon-name="svg-spinners:3-dots-bounce"
        icon-position="left"
        label="Praat met je data"
      />
    </UiHero>
    <FeaturedBlocks :featured-blocks="blocks" />
    <VideoText />
    <ContactForm />
  </div>
</template>

<script setup lang="ts">
import type {FeaturedBlock} from '~/components/FeaturedBlocks.vue';

useSeoMeta({
  title: 'SayBTTR - Praten met data!',
  description: 'Met Say BTTR kun je met je data spreken en direct inzichten opdoen die jouw markt beter begrip geven. Geen meer eindeloze zoektochten in dashboards, maar toegang tot de juiste data om trends te spotten en maak-keuzes voor groei te maken.',
});

const blocks: FeaturedBlock[] = [
  {
    to: 'sessions',
    badge: 'Sessions',
    title: 'Hoe wij bouwen aan de wereld van morgen',
    button: 'Bekijk de Say BTTR Sessions',
    image: 'https://a.storyblok.com/f/275966/448x540/5f44af2d8b/sessions-knop-tiny-knop-1.png',
  },
  {
    to: 'dataveiligheid',
    badge: 'Dataveiligheid',
    title: 'Hoe wij zorg dragen voor jouw privacy',
    button: 'Lees meer over dataveiligheid',
    image: '/featured/ethereal.jpg',
  },
  {
    to: 'chat',
    badge: 'Tool',
    title: 'Nieuwe inzichten uit je GA4 data',
    button: 'Begin te praten met je data',
    image: '/featured/flow.jpg',
  },
];
</script>
